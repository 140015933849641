// 引入 字体变量插件
import cssVars from 'css-vars-ponyfill'
// 主题色配置
const getThemeColor = (type) => {
    if (type == 'white') {
        return {
            // 直播日期列表切换
            '--liveListBackgroundColor': '#eef0f6', // 直播列表背景颜色
            '--liveActiveListBackgroundColor': '#0078ff', // 直播列表选中高亮背景
            '--liveActiveListFontColor': '#000000', // 直播列表字体颜色
            '--liveActiveListFontActiveColor': '#ffffff', // 直播列表选中字体颜色
            '--borderColor': '#EEEEEE',
            // 通用
            // '--bodyColor': '#E4E9EF', // body背景色
            '--bodyColor': '#f4f5f7', // body背景色
            '--backgroundColor':'#FFFFFF', // 主题色
            '--fontColor': '#000000', // 字体颜色
            '--fontColor1': '#a9a9ab', // 字体颜色浅色
            '--afficheColor': '#ffebeb', // 公告背景色
            // 类型切换tab
            '--tabColor':'#000000', // tab字体颜色
            '--tabActiveColor':'#0078ff', // 字体选中高亮
            '--tabActiveColorLine': '#0078ff', // 底部线条
            // 日程
            '--scheduleContentTitleDateFontColor': '#1b518f', // 日程时间title字体颜色
            '--scheduleDateTitleBgColor': '#1b518f', // 日程日期title背景color
            '--scheduleTitleBgColor': '#2763a7', // 日程日期title背景color
            '--scheduleContentBgColor': '#FFFFFF', // 日程内容背景color
            '--scheduleContentTitleFontColor': '#000000',// 日程内容title字体color
            '--scheduleContentDescFontColor': 'rgba(0,0,0,0.7)',// 日程内容title字体color
            '--labelFontColor': '#FFFFFF',
            '--labelLineColor': '#2763a7',
            '--labelRightColor': '#0077ff',
            '--scheduleListBgColor': '#f6f7fa', // 背景颜色
            
            // 分享浮窗
            '--floatBgColor': '#FFFFFF'
        }
    } else if (type == 'black') {
        return {
            // 直播日期列表切换
            '--liveListBackgroundColor': '#000000', // 直播列表背景颜色
            '--liveActiveListBackgroundColor': '#0078ff', // 直播列表选中高亮背景
            '--liveActiveListFontColor': 'rgba(255,255,255,0.7)', // 直播列表字体颜色
            '--liveActiveListFontActiveColor': '#ffffff', // 直播列表选中字体颜色
            '--borderColor': '#8f97a7',  // tab切换分割线 不变
            // 通用
            '--bodyColor': '#000000', // body背景色
            '--backgroundColor':'#1e2028', // 主题色
            '--fontColor': '#FFFFFF', // 字体颜色
            '--fontColor1': '#a9a9ab', // 字体颜色浅色
            '--afficheColor': '#000000', // 公告背景色
            // 类型切换tab
            '--tabColor':'rgba(255,255,255,0.7)', // tab字体颜色
            '--tabActiveColor':'#ffffff', // 字体选中高亮
            '--tabActiveColorLine': '#3e93f3', // 底部线条
            // 日程
            '--scheduleContentTitleDateFontColor': '#3e93f3', // 日程时间title字体颜色
            '--scheduleDateTitleBgColor': '#1b518f', // 日程日期title背景color   不变
            '--scheduleTitleBgColor': '#2763a7', // 日程日期title背景color       不变
            '--scheduleContentBgColor': '#23252e', // 日程内容背景color
            '--scheduleContentTitleFontColor': '#FFFFFF',// 日程内容title字体color
            '--scheduleContentDescFontColor': 'rgba(255,255,255,0.7)',// 日程内容title字体color
            '--labelFontColor': '#3e93f3',
            '--labelLineColor': '#000000',
            '--labelRightColor': '#3e93f3',
            '--scheduleListBgColor': '#15161a', // 背景颜色
            
            // 分享浮窗
            '--floatBgColor': '#23252e'
        } 
    } else if (type == 'blue') {
        return {
            // 直播日期列表切换
            '--liveListBackgroundColor': '#0D365B', // 直播列表背景颜色
            '--liveActiveListBackgroundColor': '#FFFFFF', // 直播列表选中高亮背景
            '--liveActiveListFontColor': 'rgba(255,255,255,0.7)', // 直播列表字体颜色
            '--liveActiveListFontActiveColor': '#0D365B', // 直播列表选中字体颜色
            '--borderColor': '#8f97a7',
            // 通用
            '--bodyColor': '#0D365B', // body背景色
            '--backgroundColor':'#124D82', // 主题色
            '--fontColor': '#FFFFFF', // 字体颜色
            '--fontColor1': '#ffffff', // 字体颜色浅色
            '--afficheColor': 'rgba(0,0,0,0.2)', // 公告背景色
            // 类型切换tab
            '--tabColor':'rgba(255,255,255,0.7)', // tab字体颜色
            '--tabActiveColor':'#ffffff', // 字体选中高亮
            '--tabActiveColorLine': '#0078ff', // 底部线条
            // 日程
            '--scheduleContentTitleDateFontColor': '#0D365B', // 日程时间title字体颜色
            '--scheduleDateTitleBgColor': 'rgba(18,77,130,0.05)', // 日程日期title背景color
            '--scheduleTitleBgColor': '#0D365B', // 日程日期title背景color
            '--scheduleContentBgColor': '#FFFFFF', // 日程内容背景color
            '--scheduleContentTitleFontColor': '#000000',// 日程内容title字体color
            '--scheduleContentDescFontColor': 'rgba(0,0,0,0.7)',// 日程内容title字体color
            '--labelFontColor': '#FFFFFF',
            '--labelLineColor': '#0D365B',
            '--labelRightColor': '#0D365B',
            '--scheduleListBgColor': '#f6f7fa', // 背景颜色

            // 分享浮窗
            '--floatBgColor': '#FFFFFF'
        }
    } else if (type == 'red') {
        return {
            // 直播日期列表切换
            '--liveListBackgroundColor': '#6e0818', // 直播列表背景颜色
            '--liveActiveListBackgroundColor': '#FFFFFF', // 直播列表选中高亮背景
            '--liveActiveListFontColor': 'rgba(255,255,255,0.7)', // 直播列表字体颜色
            '--liveActiveListFontActiveColor': '#d3001e', // 直播列表选中字体颜色
            '--borderColor': '#d3001e',
            // 通用
            '--bodyColor': '#d3001e', // body背景色
            '--backgroundColor':'#9e0b23', // 主题色
            '--fontColor': '#FFFFFF', // 字体颜色
            '--fontColor1': '#ffffff', // 字体颜色浅色
            '--afficheColor': 'rgba(0,0,0,0.2)', // 公告背景色
            // 类型切换tab
            '--tabColor':'rgba(255,255,255,0.7)', // tab字体颜色
            '--tabActiveColor':'#ffffff', // 字体选中高亮
            '--tabActiveColorLine': '#d3001e', // 底部线条
            // 日程
            '--scheduleContentTitleDateFontColor': '#3e93f3', // 日程时间title字体颜色
            '--scheduleDateTitleBgColor': '#d3001e', // 日程日期title背景color
            '--scheduleTitleBgColor': '#d3001e', // 日程日期title背景color
            '--scheduleContentBgColor': '#FFFFFF', // 日程内容背景color
            '--scheduleContentTitleFontColor': '#000000',// 日程内容title字体color
            '--scheduleContentDescFontColor': 'rgba(0,0,0,0.7)',// 日程内容title字体color
            '--labelFontColor': '#FFFFFF',
            '--labelLineColor': '#d3001e',
            '--labelRightColor': '#d3001e',
            '--scheduleListBgColor': 'rgba(211,0,30,0.05)', // 背景颜色

            // 分享浮窗
            '--floatBgColor': '#FFFFFF'
        }
    }
}

export const setThemeColor = (type) => {
    type = type || 'white';
    let themeColor = getThemeColor(type);
    cssVars({
        watch: true,
        // variables 自定义属性名/值对的集合
        variables: themeColor,
        // 当添加，删除或修改其<link>或<style>元素的禁用或href属性时，ponyfill将自行调用
        onlyLegacy: false // false 默认将css变量编译为浏览器识别的css样式 true 当浏览器不支持css变量的时候将css变量编译为识别的css
    })
    // for(let key in themeColor) {
    //     document.documentElement.style.setProperty([key],themeColor[key]);
    //     document.documentElement.style[key] = themeColor[key];
    // }
}
