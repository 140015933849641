export default {
   'liveDetail' : 'live-api/LivesDeatil',       // 直播信息
   'getLiveTabList':'live-api/GetLiveTabList',  // 直播切换列表
   'updateStudyLog':'api/UpdateStudyLog',       // 记录直播时长
   'commentHistory':'live-api/CommentHistory',  // 聊天历史
   'checkComment': 'api/CheckComment',          //校验文字文本内容
   'checkMessage': '/m/lives/check-message', // 聊天审核
   'addComment': 'live-api/AddComment',
   'onlineMessage': 'live-api/OnlineMessage',
   'getMeetingHallList': 'live-api/GetMeetingHallList',
   'getLivesProgram': 'live-api/GetLivesProgram', // 日程列表
   'getAdList': 'live-api/GetAdList', // 弹窗广告列表
   'appointment': 'live-api/Appointment', // 预约直播
   'getRegForm': 'live-api/GetRegForm', // 获取登记观看表单
   'submitFrom': 'live-api/SubmitFrom', // 提交登记观看表单接口
   'checkMm': 'live-api/CheckMm', // 校验密码观看接口
   'checkInviteCode': 'live-api/CheckInviteCode', // 校验邀请码
   'unifiedLogin': 'token/UnifiedLogin',
   'getUserInfoByUserId': 'api/GetUserInfoByUserId', // token取用户信息
   'getVideoParams': 'video/GetVideoParams', // 获取回放视频
   'getAppletImg': 'live-api/AppletImg', // 获取小程序二维码接口
   'getSignConfig': 'live-api/GetSignConfig', // 获取签到配置接口
   'liveSign': '/live-api/LiveSign', // 签到接口
   // 'getVideoToken': '/sciconf/ajax/get-token',
   // 'getVideoToken': '/ajax/get-token',
   'getVideoToken': '/video/GetToken', // 获取视频加密值验证接口
   'submitQuestion': '/live-api/SubmitQuestion', // 提交直播答题接口
   'GetVieoPlayParamsByVid':'video/GetVieoPlayParamsByVid',  // 根据vid获取视频相关信息接口
   'onlinePeople': '/live-api/OnlinePeople',
   'getLookNum': '/api-config/OnlinePeople', // 获取观看数量
   'getForbiddenWord': '/live-api-extend/GetForbiddenWord', // 获取直播严禁词
   // 'getNeedAnswer': '/sciconf/m/lives/need-answer' // 是否需要答题
   'getNeedAnswer': '/m/lives/need-answer', // 是否需要答题
   'getApiConfig': '/yv2/api-config/GetApiConfig', // 获取api域名配置
   'like': '/live-api/Like', // 点赞接口
   'getOrderDetail': '/live-api/order-detail', // 获取微信支付相关参数
   'wxPayUser': '/live-api/wx-pay-user',
   'getWxShareList': '/live-api/GetWxShareList', // 获取微信分享信息
   'getWeixinJsapi': '/wx/GetWeixinJsapi' // 获取微信签名时间戳
}
