module.exports = {
    repeatTips: 'The live broadcast has been opened in another window, please do not reopen it',
    title:'Live broadcast system',
    loginTip:'Please log in to check it out',
    loginRecord:'According to the requirements of the organizer, you can watch the live broadcast after registration',
    loginPass:'According to the requirements of the organizer, you can only watch after entering the password of the live broadcast room',
    loginReg:'According to the requirements of the organizer, you can watch the live broadcast only after registering for the conference',
    loginPay:'According to the requirements of the organizer, you can watch the live broadcast only after you register and pay the fee',
    loginCode:'According to the requirements of the organizer, you can watch the live broadcast only after entering the correct invitation code',
    loginSpecialReg:'According to the requirements of the organizer, the live broadcast can only be viewed after selecting the specified registration type',
    loginRegPay:'At the request of the organizer, this live broadcast is only open to users who register and pay for the conference!',
    loginText:'Log in immediately',
    registerText:'Immediate registration',
    liveStartText:'Livestreaming will start in',
    hits:'Clicks',
    agree:'Agree',
    refuse:'Disagree',
    autoClose:"Post automatically closed",
    skip:'Skip',
    // 表单
    form:{
        welcome:"Welcome to the live broadcast",
        regInfo:'Authentication information',
        placeHolder:'Please enter',
        name:'Name',
        mobile:'Tel',
        inviteCode:'Invitation code',
        confirm:'Confirm',
        submit:'Submit',
        requiredText:'Not null',
        regMobileText:'Please enter the correct phone format',
    },
    month: {
        '01': 'Jan',
        '02': 'Feb',
        '03': 'Mar',
        '04': 'Apr',
        '05': 'May',
        '06': 'Jun',
        '07': 'Jul',
        '08': 'Aug',
        '09': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec'
    },
    // 倒计时
    countDown: {
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        seconds: 'Seconds'
    },
    // 聊天
    message:{
        anonymous:'Anonymous',
        sendBtn:'Send',
        charText:'Chat',
        questionText:'Q&A',
        moreMessage:'More',
        moreQuestion:'More',
        joinCharBtn:'Join the chat...',
        joinQuestionBtn:'I want ask...',
    },
    // 播放器
    player:{
        videoErrText: "This video can't be played right now. Please try again later",
        code0:"Media customization error, please refresh and try again",
        code1:"Media error aborted, please refresh and try again",
        code2:"Media error network, please check the network and refresh and try again",
        code3:"Media error code, please refresh and try again",
        code4:"Media format is not supported. Please refresh and try again",
        code5:"Media encryption error, please contact the website administrator"
    },
    // 日程
    program:{
        title:"Programme",
        chairman:'President',
        host:'Preside',
        time:'Date',
        hall:'Hall',
    },
    // 直播间列表
    liveRoom:{
        playback:'Playback',
        living:'Live',
        lived:'Finished',
        beginText:'Start',
    },
    // 预约直播
    liveAppointment:{
        reserved:'Already Subscribed',
        reserveLive:'Subscribe',
        reserveNum:'Person(s) are already subscribed',
        name:'Name',
        org:'Organisation',
        reserveTime:'Time'
    },
    // 答题
    answer:{
        title:'Interactive answer',
        radio:'Radio',
        checkbox:'MultiSelect',
        submit:'Submit',
        maxTip:'The most choices for the current question',
        minTip:'题最少选择',
        xiang:'Item',
        di:'第',
        requiredText:'项是必选项',
        repeatTip:'You have submitted this question and answer. Please do not submit it again',
    },
    // 浮窗
    float:{
        liveSign:'Live check-in',
        sign:'Sign in',
        signed:"Signed in",
        noSign:'Not signed in',
        share:'Share',
        miniprogram:'Applet',
        watch:'Watch',
        mpweixin:"Wechat viewing",
        scanQrcode:'Scan QR codes',
        scanQrcodePoster:'Scan QR code below to share poster',
        miniProgramTip:'Long press to go to the applet terminal to watch',
        shareFriend:'Share friends',
        shareRank:'Share ranking',
        sharePoster:'Share posters',
        copyTip:'The link has been copied. Go to WeChat and paste it to friends, or share it with friends in the upper right corner of WeChat browser',
        copyFrendTip:'The link has been copied. Go to WeChat and paste it to your friend',
        signSuccessText:'Sign in successfully'
    }
}