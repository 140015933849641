<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
// 主题色配置
import { setThemeColor } from './utils/themeColor';
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
export default {
  watch:{
    '$route' () {
      let { theme,lang } = this.$route.query;
      // 设置皮肤
      setThemeColor(theme);
      // 设置语言
      this.$i18n.locale = lang || 'cn';
      // 设置vant语言包
      if (lang == 'en') {
        Locale.use('en-US', enUS);
      }
    }
  }
}
</script>
<style lang="less">
*{
  margin:0;
  padding:0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size:0.6rem;
}

@media screen and (min-width:769px){
  html{
    font-size:26px!important;
  }
}

// @media screen and (min-width:1367px){
//   html{
//     font-size:26px!important;
//   }
// }

</style>
