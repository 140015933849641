// with polyfills
import 'core-js';
import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// 语言包
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
   //定义默认语言
   locale: 'cn',
   messages: {
    'cn': require('./language/cn'),
    'en': require('./language/en')
   }
})
  
// 导入vant UI
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// 引入dayjs
import dayjs from 'dayjs';
Vue.prototype.dayjs = dayjs;

// 引入全局过滤器
require('./utils/filters');

// 引入hotcss 
require('./lib/hotcss');

Vue.config.productionTip = false
// 如果参数中存在unified则代表需要登录
function routeHandle (to, form, next) {
  if (to.query.unified) {
    request.post('unifiedLogin', {
      unified: to.query.unified
    }).then((res) => {
      localStorage.setItem('auth', res.data)
      request.get('getUserInfoByUserId').then(res => {
          store.commit('setUserInfo', res.data);
          let query = JSON.parse(JSON.stringify(to.query));
          query.unified && (delete query.unified);
          next({
              path: to.path,
              query
          })
          localStorage.setItem('isLogin', '1')
      }).catch ((err) => {
        if (err.status == 401) {
          localStorage.setItem('isLogin', '0') // 未登录
        }
      })
    })
  } else {
    if (!(store.getters.userInfo||{}).user_id) {
        request.get('getUserInfoByUserId',{},{hideModal:true}).then(res => {
            store.commit('setUserInfo', res.data);
            localStorage.setItem('isLogin', '1')
            next();
        }).catch((err) => {
            if (err.status == 401) {
                localStorage.setItem('isLogin', '0') // 未登录
            }
            next()
        })
    } else {
        next()
    }
  }
}
router.beforeEach((to, form, next) => {
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
  request.get('getApiConfig').then((res) => {
    if (res && res.code == 200) {
      localStorage.setItem('apiConfig', JSON.stringify(res.data))
    } else {
      localStorage.removeItem('apiConfig')
    }
    routeHandle(to, form, next)
  }).catch((err) => {
    localStorage.removeItem('apiConfig')
    routeHandle(to, form, next)
  })
})
// 引入全局样式
import '@/assets/css/app.less';

// 全局拦截去
import * as request from '@/request/common'
Vue.prototype.request = request;

// 引入全局类型
const judge = require('./utils/judgeDataType');
Vue.prototype.judge = judge;

// IE10以下提示信息
import '@/utils/ie10-tips.js';

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
