import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      Uid: 0,
      Avatar:'https://files.sciconf.cn/asset/mobile/speaker/images/person-default.png',
      RealName:"",
    },
    // 片头广告
    prerollConfigAdv: {},
    // 暂停广告
    stopConfigAdv: {},
    // 没有直播时的封面图
    live_banner:'',
    // 直播流参数
    sdk_params: {},
    // 直播配置参数
    live_config: {
      meeting_hall: '1'
    },
    // 直播返回的用户信息
    live_user_info:{},
    // 直播间基本信息
    liveDetail:{
    },
    // 直播状态
    liveStatus: false,
    // 视频列表当前选中时间
    liveTabDate: '',
    aggree_tan_content: '', // 观看须知
    messageInfo:{},
    isPower: true, // 权限是否通过
    isMessage: false, // tabList是否存在聊天
    forbiddenWordList: [], // 互动留言严禁词列表
    openid: '', // 微信openid
    meeting_id: ''
  },
  getters:{
    userInfo:state => state.userInfo, 
  },
  mutations: {
    setOpenId (state, data) {
      state.openid = data
    },
    setForbiddenWordList (state, data) {
      state.forbiddenWordList = data
    },
    setIsMessage (state, data) {
      state.isMessage = data
    },
    setIsPower (state, data) {
        state.isPower = data
    },
    setTanContent (state, data) {
      state.aggree_tan_content = data
    },
    setMeetingId (state, data) {
      state.meeting_id = data
    },
    setPrerollConfigAdv (state, data) {
      state.prerollConfigAdv = data
    },
    setStopConfigAdv (state, data) {
      state.stopConfigAdv = data
    },
    setLiveBanner (state, data) {
      state.live_banner = data
    },
    setSdkParams (state, data) {
      state.sdk_params = data
    },
    setLiveConfig (state, data) {
      state.live_config = data
    },
    setLiveUserInfo (state, data) {
      state.live_user_info = data
    },
    setLiveDetail (state, data) {
      state.liveDetail = data
    },
    setLiveStatus (state, data) {
      state.liveStatus = data
    },
    setLiveTabDate (state, data) {
      state.liveTabDate = data
    },
    setUserInfo (state, data) {
      state.userInfo = data
    },
    setMessageInfo(state,data) {
      state.messageInfo = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
  ]
})
