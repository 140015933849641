import Vue from 'vue';
// 相对路径改成绝对路径方法
Vue.filter('urlFilter',function (url, s) {
    s = s ? ('!wx' + s) : '';
    var baseUrl = 'https://files.sciconf.cn';
    if (!url) {
      url = '';
    }else if (url.indexOf('gif') != '-1'){
      return url;
    }else if (url.indexOf('wxfile:/') == -1) {
      url = url.replace('!avatar', '')
      if (url.indexOf('http') != -1) { // 全路径
        url = url.indexOf(baseUrl) == -1 ? url : url + s;
      } else if (url.indexOf('../') != -1) { // 本地图片
        url = url;
      } else if (url.indexOf('./') != -1) { // 相对路径
        url = baseUrl + url.replace('./', '') + s;
      } else { // 
        url = baseUrl + '/' + url + s;
      }
    }
    // 使用压缩图
    return url
  })