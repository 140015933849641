module.exports = {
    repeatTips: '该直播已在其它窗口打开，请勿重复打开',
    title:'直播系统',
    loginTip:'请登录后观看',
    loginRecord:'根据主办方要求,登记后才可以观看直播',
    loginPass:'根据主办方要求,输入直播间密码后才可观看',
    loginReg:'根据主办方要求,注册参会后才可以观看直播',
    loginPay:'根据主办方要求，注册参会缴费后才可以观看直播',
    loginCode:'根据主办方要求,输入正确邀请码后才可以观看直播',
    loginSpecialReg:'根据主办方要求,该直播需要选择指定注册类型后才可以观看',
    loginRegPay:'本直播应主办方要求，只针对参会注册并交费的用户开放！',
    loginText:'立即登录',
    registerText:'立即注册',
    liveStartText:'距直播开始还有',
    hits:'点击量',
    agree:'同意',
    refuse:'拒绝',
    autoClose:"后自动关闭",
    skip:'跳过',
    // 表单
    form:{
        welcome:"欢迎观看本直播",
        regInfo:'验证信息',
        placeHolder:'请输入',
        name:'姓名',
        mobile:'手机号',
        inviteCode:'邀请码',
        confirm:'确 认',
        submit:'提 交',
        requiredText:'不能为空',
        regMobileText:'请输入正确的手机格式',
    },
    // 倒计时
    countDown: {
        days: '天',
        hours: '时',
        minutes: '分',
        seconds: '秒'
    },
    // 聊天
    message:{
        anonymous:'匿名',
        sendBtn:'发 送',
        charText:'聊天',
        questionText:'提问',
        moreMessage:'点击查看更多留言',
        moreQuestion:'点击查看更多提问',
        joinCharBtn:'我也来参与一下互动...',
        joinQuestionBtn:'我想问讲师...',
    },
    // 播放器
    player:{
        videoErrText:'此视频暂无法播放，请稍后再试',
        code0:"媒体自定义错误，请刷新重试",
        code1:"媒体错误中止，请刷新重试",
        code2:"媒体错误网络，请检查网络后刷新重试",
        code3:"媒体错误代码，请刷新重试",
        code4:"不支持媒体格式，请刷新重试",
        code5:"媒体加密错误，请联系网站管理员"
    },
    tcplayer: {
        videoErrText:'此视频暂无法播放，请稍后再试',
        code0:"播放器没有检测到可用的视频地址",
        code1:"获取视频数据超时",
        code2:"媒体错误网络，请检查网络后刷新重试",
        code3:"媒体错误代码，请刷新重试",
        code4:"不支持媒体格式，请刷新重试",
        code5:"媒体加密错误，请联系网站管理员"
    },
    // 日程
    program:{
        title:"日程安排",
        chairman:'主席',
        host:'主持',
        time:'时间',
        hall:'会场',
    },
    // 直播间列表
    liveRoom:{
        playback:'直播回放',
        living:'直播中',
        lived:'已结束',
        beginText:'开播',
    },
    // 预约直播
    liveAppointment:{
        reserved:'已预约',
        reserveLive:'预约直播',
        reserveNum:'人已预约',
        name:'姓名',
        org:'单位',
        reserveTime:'预约时间'
    },
    // 答题
    answer:{
        title:'互动答题',
        radio:'单选',
        checkbox:'多选',
        submit:'提 交',
        maxTip:'当前题最多选择',
        minTip:'题最少选择',
        xiang:'项',
        di:'第',
        requiredText:'项是必选项',
        repeatTip:'您已提交过该问答，请勿重复提交',
    },
    // 浮窗
    float:{
        liveSign:'直播签到',
        sign:'签到',
        signed:"已签到",
        noSign:'未签到',
        share:'分享',
        miniprogram:'小程序',
        watch:'观看',
        mpweixin:"微信观看",
        scanQrcode:'扫码',
        scanQrcodePoster:'扫码下方二维码分享海报',
        miniProgramTip:'长按前往小程序端进行观看',
        shareFriend:'分享好友',
        shareRank:'分享排行',
        sharePoster:'分享海报',
        copyTip:'链接已复制，去微信粘贴给好友，或在微信浏览器右上角中分享给好友',
        copyFrendTip:'链接已复制，去微信粘贴给好友',
        signSuccessText:'签到成功'
    }
}