import axios from 'axios'
import storage from 'store'
import * as judgeType from '@/utils/judgeDataType'
import {
    Toast,
    Dialog
} from 'vant';
import {
    committee_id,
    source,
    key
} from '@/config';
import NProgress from 'nprogress' // 引入nprogress插件
import 'nprogress/nprogress.css' // 这个nprogress样式必须引入
import qs from 'qs';
let stepNum = 0; // 请求计步器
let pageInitTime = +(new Date()); // 页面初始化时间
let loadingInstance; //创建Loading 的实例
let isLiveDetailSuccess = false
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL; // 配置axios请求的地址
// axios.defaults.baseURL = "https://api.juzhanhui.net/"; // 配置axios请求的地址
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers['key'] = key;
axios.defaults.headers['committeeID'] = committee_id;
axios.defaults.headers['source'] = source;
// 请求拦截器
axios.interceptors.request.use(
    async config => {
        let { costom } = config;
        if (config.url.indexOf('GetApiConfig') != -1) {
            config.baseURL = 'https://apifc-g.sciconf.cn' // 获取api配置域名
        } else if (config.url.indexOf('check-message') != -1 || config.url.indexOf('get-token') != -1 || config.url.indexOf('need-answer') != -1) {
            config.baseURL = 'https://www.sciconf.cn/'
        } else {
            if (localStorage.getItem('apiConfig')) { // 配置域名
                const { applet_api_url, go_api_list, go_api_url } = JSON.parse(localStorage.getItem('apiConfig'))
                config.baseURL = applet_api_url
                const str = config.url.substr(config.url.lastIndexOf('/') + 1)
                for (let i = 0; i < go_api_list.length; i++) {
                    if (config.url == go_api_list[i] || go_api_list[i].indexOf(str) != -1) {
                        config.baseURL = go_api_url
                        break;
                    }
                }
            } else { // 使用默认域名
                config.baseURL = 'https://apifc.sciconf.cn/yv2/'
            }
        }
        // 计步器加1
        if (stepNum == 0 && !(costom||{}).hideLoading) {
            // 打开loading
            NProgress.start();
            loadingInstance = Toast.loading({
                message: 'loading...',
                forbidClick: true,
            });
        }
        ++stepNum;
        const token = localStorage.getItem('auth') || ''
        // 如果 token 存在
        // 让每个请求携带自定义 token 请根据实际情况自行修改
        if (token) {
            config.headers['auth'] = token
        }
        // 处理liveDetail请求之后再发起其他请求
        if (config.url.indexOf('LivesDeatil') != -1 || config.url.indexOf('GetApiConfig') != -1 || config.url.indexOf('GetUserInfoByUserId' != -1)) {
            return config
        } else {
            if (isLiveDetailSuccess) {
                return config
            }
            const delay = () => new Promise(resolve => setInterval(() => {
                if (isLiveDetailSuccess) {
                    resolve()
                }
            }, 50))
            return delay().then(() => {
                return config
            })
        }
        // return config
    },
    error => {
        return Promise.error(error);
    }
);


axios.interceptors.response.use(
    response => {
        if (response.config.url.indexOf('LivesDeatil') != -1) {
            isLiveDetailSuccess = true
        }
        // 计步器减一
        --stepNum;
        if (stepNum == 0) {
            // 关闭loading
            setTimeout(() => {
                NProgress.done();
                loadingInstance.clear();
            }, 300)
        }
        let custom = response.config.costom || {}
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            // 统一处理非200提示
            let {
                code,
                message
            } = response.data
            // hideModal 如果为true则隐藏错误弹框
            if (code != 200 && message && !custom.hideModal) {
                Dialog.alert({
                    message,
                }).then(() => {
                    // on close
                });
            }
            return Promise.resolve(response.data);
        } else {
            if (custom.isSuccessData) {
                return Promise.resolve(response.data);
            } else {
                return Promise.reject(response.data);
            }
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        // 错误后关闭所有loading
        // 计步器减一
        --stepNum;
        if (stepNum == 0) {
            // 关闭loading
            setTimeout(() => {
                NProgress.done();
                loadingInstance.clear();
            }, 300)
        }

        if (error.response && error.response.status) {
            const lock = error.response.config.url.indexOf('user-center/') > -1;
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    // Toast("身份验证失败");
                    storage.remove('auth');
                    break;

                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                case 403:
                    Toast("登录过期，请关闭重新进入。");
                    // 清除token
                    break;

                    // 404请求不存在
                case 404:
                    Toast("您访问的网页不存在。");
                    break;

                    // 404请求不存在
                case 500:
                    Toast("数据加载出错");
                    break;
                    // 其他错误，直接抛出错误提示
                default:
                    Toast(error.response.data.message);
            }
            return Promise.reject(error.response.data);
        }
    }
);
export default axios;