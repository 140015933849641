import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/:id/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index.vue')
  },
  {
    path: '/lookPower/:id/:lookPowerType/',
    name: 'lookPower',
    component: () => import('@/views/lookPower.vue')
  },
  {
    path: '/tips',
    name: 'tips',
    component: () => import('@/views/tips.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
